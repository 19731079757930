<template>
    <div class="footer-area-wrapper" :class="FooterStyle">
        <div class="footer-area section-space--ptb_60 border-top w-100">
            <div class="container mobile-mw-100 m-sm-3 mx-lg-5">
                <div class="row align-items-start footer-widget-wrapper">
                    <div class="col-lg-4 col-md-6 footer-widget">
                        <div class="footer-widget__logo mb-30">
                            <router-link to="/">
                                <img src="../assets/img/logo/logo_groupeaa.png" class="img-fluid logo-dark" :alt="$t('navBar.logoAlt')" width="200px" height="32.328px">
                                <img src="../assets/img/logo/logo-light.png" class="img-fluid logo-light" :alt="$t('navBar.logoAlt')" width="360px" height="68px">
                            </router-link>
                        </div>
                        <ul class="footer-widget__list">
                            <li>{{ $t('footer.cityList') }}</li>
                            <li><a class="hover-style-link footer-link-color" href="tel:+18883615051" @click="sendGtag()">1 888 361-5051</a></li>
                            <li><a class="hover-style-link footer-link-color" href="https://www.groupeaa.com/" target="_blank" rel="nofollow noopener">https://www.groupeaa.com/</a></li>
                            <li class="copyright">© {{ new Date().getFullYear() }} Le Groupe A&A. {{ $t('footer.rights') }}</li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-6 footer-widget">
                        <h2 class="footer-widget__title mb-25 pt-3 h6">{{ $t('footer.aboutSection') }}</h2>
                        <ul class="footer-widget__list">
                            <li><a class="hover-style-link" :href="$t('footer.aboutUsLink')" target="_blank" rel="nofollow noopener">{{ $t('footer.aboutUs') }}</a></li>
                            <!-- <li><a class="hover-style-link" :href="$t('footer.faqLink')" target="_blank" rel="nofollow noopener">{{ $t('footer.faq') }}</a></li> -->
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-6 footer-widget">
                        <h2 class="footer-widget__title mb-25 pt-3 h6">{{ $t('footer.quickLinks') }}</h2>
                        <ul class="footer-widget__list">
                            <li><a class="hover-style-link" :href="$t('footer.technicalSupportLink')" target="_blank" rel="nofollow noopener">{{ $t('footer.technicalSupport') }}</a></li>
                            <li><a class="hover-style-link" :href="$t('footer.clientAccessLink')" target="_blank" rel="nofollow noopener">{{ $t('footer.clientAccess') }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- ! Social media not used for the time being. If used, will need to update CSS. NL, Sept 2021.
        <div class="footer-copyright-area border-top section-space--ptb_30">
            <div class="container">
                <div class="row align-items-center" style="display:flex; justify-content:end;">
                    <div class="col-md-6 text-center text-md-right">
                        <ul class="list ht-social-networks solid-rounded-icon">
                            <li class="item"><a href="https://www.facebook.com/" target="_blank" class="social-link"> <i class="fab fa-facebook social-link-icon"></i></a></li>
                            <li class="item"><a href="https://twitter.com/" target="_blank" class="social-link"> <i class="fab fa-twitter social-link-icon"></i> </a></li>
                            <li class="item"><a href="https://www.instagram.com/" target="_blank" class="social-link"> <i class="fab fa-instagram social-link-icon"></i> </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: 'FooterMain',
        props: ["FooterStyle"],
        methods: {
            sendGtag(){
                if(typeof window.gtag !== 'undefined'){
                    window.gtag('event', 'Phone', {'event_category': '', 'event_label': ''});
                }
            }
        }
    }
</script>
