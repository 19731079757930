<template>
	<div class="main-container" style="display: flex;">
		<div class="navBarTest">
			<Navbar />
		</div>
		<div class="thanks my-5 py-5" style="display:flex; justify-content:center; align-items:center;">
			<h1 class="mb-3 h3">Groupe A&amp;A</h1>
			<p class="thank-you-text mb-0">{{ $t('pageRemerciement.thankYouText') }}</p>
			<p class="thank-you-text mb-0">{{ $t('pageRemerciement.feedbackText') }}</p>
			<router-link :to="$t('links.accueil')" class="mt-3 tertiary-title">&lsaquo; {{ $t('general.backToHomePage') }}</router-link>
		</div>
	</div>
</template>

<script>
	import Navbar from '../components/Navbar.vue'

	export default {
		name: 'PageRemerciement',
		components: {
			Navbar,
		},
		data() {
			return {
				load: false,
				limitPosition: 200,
				scrolled: false,
				lastPosition: 500,
				art: [{
						title: 'Equipements',
						link: '#',
					},
					{
						title: 'Logiciels',
						link: '#',
					},
					{
						title: 'Encre et Medias',
						link: '#',
					},

				]
			}
		},
		metaInfo(){
			return {
				title: this.$i18n.t('tabTitle.thanks'),
				meta: [
					{ "name": "theme-color", "content": "#007BFF;" }
				]
			}
		},
		created(){
			if(typeof window.gtag !== 'undefined'){
				window.gtag('config', process.env.VUE_APP_GTAG_UA, {
						'page_title' : this.$i18n.t('tabTitle.thanks'),
						'page_path': this.$i18n.t('links.remerciement')
				});
				window.gtag('config', process.env.VUE_APP_GTAG_G, {
						'page_title' : this.$i18n.t('tabTitle.thanks'),
						'page_path': this.$i18n.t('links.remerciement')
				});
			}
			if(typeof window.fbq !== 'undefined'){
				window.fbq('track', 'PageView');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/_page-soumission.scss'
</style>