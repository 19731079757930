// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import fr from './fr.json'

Vue.use(VueI18n)

const defaultLocale = 'fr'

const messages = {
	en: en,
	fr: fr,
}

export default new VueI18n({
	locale: defaultLocale,
	fallbackLocale: 'fr',
	messages
})