<template>
	<div>
		<div class="main-container">
			<div class="navBarTest">
				<Navbar />
			</div>
			<div class="secondary-title-wrapper col-lg-12" style="margin-bottom:-150px; border-bottom: solid #007cc3">
				<h1 class="h3 my-0 mx-lg-5 secondary-title">{{ $t('pageEquipement.mainTitle') }}</h1>
			</div>
			<div class="box-image-wrapper mx-auto px-2 px-lg-4 mw-100" style="display: flex;justify-content: space-around;margin-top: 175px;">
				<div>
					<div class="mx-auto" style="max-width: 1240px;">
						<p class="text-center my-4 px-2">{{ $t('pageEquipement.mainText') }}</p>
					</div>

					<div class="row mx-auto" style="max-width: 1240px;">
						<div class="col col-12 col-lg-4 mb-4">
							<router-link :to="$t('links.printerLandingPage')" class="hoverR w-100 h-100">
								<div class="contact-form-wrapper" style="height: 100%;">
									<img class="imgPage w-70 mx-auto d-block img-height" src="../assets/img/hero/branding/Z9.png" :alt="$t('printerLandingPage.imgAlt')">
									<div style="white-space: pre-line;" class="text-center my-4 px-3">
										<h2 class="h6 mb-4">{{ $t('printerLandingPage.mainTitle') }}</h2>

										<span class="slider-button mt-30 text-center text-md-left">
											<button class="ht-btn ht-btn-md">{{ $t('pageEquipement.callToActionShort') }}</button>
										</span>
									</div>
								</div>
							</router-link>
						</div>

						<div class="col col-12 col-lg-4 mb-4">
							<router-link :to="$t('links.hpLatexLandingPage')" class="hoverR w-100 h-100">
								<div class="contact-form-wrapper" style="height: 100%;">
									<img class="imgPage w-70 mx-auto d-block img-height" src="../assets/img/hero/branding/HP_Latex.png" :alt="$t('hpLatexLandingPage.imgAlt')">
									<div style="white-space: pre-line;" class="text-center my-4 px-3">
										<h2 class="h6 mb-4">{{ $t('hpLatexLandingPage.mainTitle') }}</h2>

										<span class="slider-button mt-30 text-center text-md-left">
											<button class="ht-btn ht-btn-md">{{ $t('pageEquipement.callToActionShort') }}</button>
										</span>
									</div>
								</div>
							</router-link>
						</div>

						<div class="col col-12 col-lg-4 mb-4">
							<router-link :to="$t('links.hpLatex630LandingPage')" class="hoverR w-100 h-100">
								<div class="contact-form-wrapper" style="height: 100%;">
									<img class="imgPage w-70 mx-auto d-block img-height" src="../assets/img/hero/branding/HP_Latex_630_Side.png" :alt="$t('hpLatex630LandingPage.imgAlt')">
									<div style="white-space: pre-line;" class="text-center my-4 px-3">
										<h2 class="h6 mb-4">{{ $t('hpLatex630LandingPage.mainTitle') }}</h2>

										<span class="slider-button mt-30 text-center text-md-left">
											<button class="ht-btn ht-btn-md">{{ $t('pageEquipement.callToActionShort') }}</button>
										</span>
									</div>
								</div>
							</router-link>
						</div>

						<div class="col col-12 col-lg-4 mb-4">
							<router-link :to="$t('links.pageWideLandingPage')" class="hoverR w-100 h-100">
								<div class="contact-form-wrapper" style="height: 100%;">
									<img class="imgPage w-70 mx-auto d-block img-height" src="../assets/img/hero/branding/HP_Pagewide.png" :alt="$t('pageWideLandingPage.imgAlt')">
									<div style="white-space: pre-line;" class="text-center my-4 px-3">
										<h2 class="h6 mb-4">{{ $t('pageWideLandingPage.mainTitle') }}</h2>

										<span class="slider-button mt-30 text-center text-md-left">
											<button class="ht-btn ht-btn-md">{{ $t('pageEquipement.callToActionShort') }}</button>
										</span>
									</div>
								</div>
							</router-link>
						</div>
					</div>

					<!-- <div class="description col-lg-6 box-image position-relative text-center wow move-up" style="visibility: visible; display: flex; flex-direction: column;align-items:center;">
						<img class="imgPage w-70" src="../assets/img/hero/branding/imprimante_art_graphique.jpg" :alt="$t('pageEquipement.imgAlt')">
						<p class="text-center my-4">{{ $t('pageEquipement.mainText') }}</p>
					</div>
					<div class="col-lg-5 col-12"> -->
					<!-- </div> -->
					<div class="px-2" style="max-width: 640px;margin-left: auto;margin-right: auto;">
						<ContactForm :boolEmbeded="true" :ctaText="$t('pageEquipement.callToAction')" />
					</div>
				</div>
			</div>
		</div>
		<div>
			<FooterMain />
		</div>
	</div>
</template>

<script>
	import Navbar from '../components/Navbar.vue'
	import ContactForm from '../components/ContactForm.vue'
	import FooterMain from '../components/FooterMain.vue'

	export default {
		name: 'PageEquipements',
		components: {
			Navbar,
			ContactForm,
			FooterMain
		},
		metaInfo(){
			return {
				title: this.$i18n.t('tabTitle.equipment'),
				meta: [
					{ "name": "description", "content":  this.$i18n.t('meta.description.equipment') },
					{ "name": "theme-color", "content": "#007BFF;" }
				]
			}
		},
		created(){
			if(typeof window.gtag !== 'undefined'){
				window.gtag('config', process.env.VUE_APP_GTAG_UA, {
					'page_title' : this.$i18n.t('tabTitle.equipment'),
					'page_path': this.$i18n.t('links.equipements')
				});
				window.gtag('config', process.env.VUE_APP_GTAG_G, {
					'page_title' : this.$i18n.t('tabTitle.equipment'),
					'page_path': this.$i18n.t('links.equipements')
				});
			}
			if(typeof window.fbq !== 'undefined'){
				window.fbq('track', 'PageView');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/page-equipements.scss'

</style>

<style>
	.hoverR:hover {
		color: #007cc3 !important;
	}
	.img-height {
		min-height: 200.19px;
	}
</style>