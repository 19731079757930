<template>
	<div class="feature-icon-list-area">
		<div class="requirements-list-area requirements-list-area-background py-5" id="requirement">
			<div class="row align-items-center mw-100 mx-auto">
				<div class="col-sm-12 col-lg-6 order-2 order-xl-1">
					<div class="requirements-list">
						<router-link :to="$t('links.logiciels')" class="main-title mb-30 wow move-up">{{ $t('homePage.softwareTitle') }}</router-link>
						<p class="requirements-list__text heading mb-30 wow move-up mobile-mw-100 tablet-mw-100">{{ $t('homePage.softwareText') }}</p>
						<div class="tab-button wow move-up">
							<router-link :to="$t('links.logiciels')" class="ht-btn ht-btn-md">{{ $t('general.moreInfoBtn') }}</router-link>
						</div>
					</div>
				</div>
				<div class="col-lg-6 order-12 order-xl-1 wow move-up row justify-content-center">
					<img src="../assets/img/hero/branding/logiciel_rip.png" class="img-fluid w-65 tablet-width-75 mobile-w-90 sm-to-lg-mobile-w-50" :alt="$t('homePage.softwareImgAlt')" width="514.141px" height="440.219px">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'featureList',

		data() {
			return {

			}
		}
	}
</script>