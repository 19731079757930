<template>
	<div>
		<div class="main-container">
			<div class="navBarTest">
				<Navbar />
			</div>

			<div class="secondary-title-wrapper col-lg-12" style="margin-bottom:-150px; border-bottom: solid #007cc3">
				<h1 class="h3 my-0 mx-lg-5 secondary-title">{{ $t('pageEncreMedias.mainTitle') }}</h1>
			</div>
			<div class="row box-image-wrapper mx-auto px-lg-4 mw-100" style="display: flex;justify-content: space-around;margin-top: 175px;">
				<div class="col-lg-6 box-image position-relative text-center wow move-up" style="visibility: visible; display: flex; flex-direction: column; align-items:center;">
					<img class="imgPage" src="../assets/img/hero/branding/papier_peint_vinyle.png" style="" :alt="$t('pageEncreMedias.imgAlt')">
          <h2 class="tertiary-title mt-4">{{$t('pageEncreMedias.secondaryTitle1')}}</h2>
					<p class="text-center mt-1">{{ $t('pageEncreMedias.mainTextP1') }}</p>
          <h2 class="tertiary-title">{{$t('pageEncreMedias.secondaryTitle2')}}</h2>
					<p class="text-center mt-1">{{ $t('pageEncreMedias.mainTextP2') }}</p>
				</div>

				<div class="col-lg-5 col-12">
					<ContactForm :boolEmbeded="true" :ctaText="$t('pageEncreMedias.callToAction')" />
				</div>
			</div>
		</div>
		<div>
			<FooterMain />
		</div>
	</div>
</template>

<script>
	import Navbar from '../components/Navbar.vue'
	import ContactForm from '../components/ContactForm.vue'
	import FooterMain from '../components/FooterMain.vue'

	export default {
		components: {
			Navbar,
			ContactForm,
			FooterMain,
		},
		metaInfo(){
			return {
				title: this.$i18n.t('tabTitle.inkMedia'),
				meta: [
					{ "name": "description", "content":  this.$i18n.t('meta.description.inkMedia') },
					{ "name": "theme-color", "content": "#007BFF;" }
				]
			}
		},
		created(){
			if(typeof window.gtag !== 'undefined'){
				window.gtag('config', process.env.VUE_APP_GTAG_UA, {
						'page_title' : this.$i18n.t('tabTitle.inkMedia'),
						'page_path': this.$i18n.t('links.mediasEtEncres')
				});
				window.gtag('config', process.env.VUE_APP_GTAG_G, {
						'page_title' : this.$i18n.t('tabTitle.inkMedia'),
						'page_path': this.$i18n.t('links.mediasEtEncres')
				});
			}
			if(typeof window.fbq !== 'undefined'){
				window.fbq('track', 'PageView');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/page-equipements.scss'
</style>