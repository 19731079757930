<template>
	<section class="hero-branding bg-cover col-lg-12 bg-img" :style="bgImg" id="home">
		<div class="container-fluid container-fluid--cp-150 mobile-px-0">
			<div class="hero-branding responsive-hero-branding">
				<div class="hero-content">
					<h1 class="h1 main-title text-center text-md-left">{{ $t('homePage.mainTitle') }}</h1>
					<p class="h5 hero-content-subtitle mt-20 text-center text-md-left">{{ $t('homePage.mainText') }}</p>
					<div class="slider-button mt-30 text-center text-md-left">
						<router-link :to="$t('links.soumission')" class="ht-btn ht-btn-md">{{ $t('general.getQuoteBtn') }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				bgImg: {
					backgroundImage: `url(${require('../assets/img/hero/branding/solutions_art_graphique_groupeaa.png')})`,
				},
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/hero-branding.scss';

	.bg-img {
		background-repeat: no-repeat;
	}
</style>