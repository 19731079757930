import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import VueSilentbox from 'vue-silentbox'
import {VueMasonryPlugin} from 'vue-masonry'
import WOW from 'wow.js/dist/wow.js'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/css/fontawesome-all.min.css'
import 'swiper/dist/css/swiper.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'slick-carousel/slick/slick.css'

Vue.config.productionTip = false
Vue.config.productionSourceMap = false

Vue.use(VueMasonryPlugin)
Vue.use(VueSilentbox)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(Vuelidate)
Vue.use(VueCookies)


// * Cookies default config
Vue.$cookies.config('120d');


// * Routing with Translation
import { routes } from './router/index.js'
import i18n from './i18n/index.js'

const router = new VueRouter({
	routes,
	mode: 'history'
})

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

	// use the language from the routing param or default language
	let nextLanguage = to.params.lang;
	if (!nextLanguage) {
		nextLanguage = 'fr'
	}

	window.scrollTo(0, 0);

	// set the current language for i18n.
	i18n.locale = nextLanguage;

	// change html lang attribute for accessibility
	const htmlTag = window.document.querySelector("html");
	htmlTag.lang = i18n.locale === "fr" ? "fr-CA" : "en-CA";

	next();
})


new Vue({
	render: h => h(App),
	router,
	i18n,
    mounted(){
			new WOW().init();
			document.dispatchEvent(new Event('render-event'));
    }
}).$mount('#app')
