
import Root from "./Root"
import HomeBranding from '../pages/HomeBranding.vue'
import NotFound from '../pages/elements/404.vue'
import PageEquipements from '../pages/PageEquipements.vue'
import PageLogiciels from '../pages/PageLogiciels.vue'
import PageEncreMedias from '../pages/PageEncreMedias.vue'
// import PageArtGraphic from '../pages/PageArtGraphic.vue'
import PageServices from '../pages/PageServices.vue'
import PageSoumission from '../pages/PageSoumission.vue'
import PageRemerciement from '../pages/PageRemerciement.vue'
import PrinterLandingPage from '../pages/PrinterLandingPage.vue'
import imprimantesHpLatex from '../pages/imprimantesHpLatex.vue'
import imprimantesHpLatex630 from '../pages/imprimantesHpLatex630.vue'
import imprimantesHpLatex700800 from '../pages/imprimantesHpLatex700800.vue'
import imprimantesHpLatex10002000 from '../pages/imprimantesHpLatex10002000.vue'
import imprimantesHpPagewide from '../pages/imprimantesHpPagewide.vue'

export const routes = [
	{
		path:'/',
		component: Root,
		children:[
			{
				path:'',
				name: 'GroupeAA',
				component: HomeBranding
			},
			{
				path:'/imprimantes-art-graphique',
				name: 'Équipements',
				component: PageEquipements
			},
			{
				path:'/logiciels',
				name: 'PageLogiciels',
				component: PageLogiciels
			},
			{
				path:'/medias-et-encres',
				name: 'PageEncreMedias',
				component: PageEncreMedias
			},
			{
				path:'/service',
				name: 'Services',
				component: PageServices
			},
			{
				path:'/soumission',
				name: 'Soumission',
				component: PageSoumission
			},
			{
				path:'/remerciement',
				name: 'Remerciement',
				component: PageRemerciement
			},
			{
				path:'/imprimantes-hp-serie-z-designjet',
				name: 'PrinterLandingPage',
				component: PrinterLandingPage
			},
			{
				path:'/imprimantes-hp-latex',
				name: 'PrinterLatexLandingPage',
				component: imprimantesHpLatex
			},
			{
				path:'/imprimantes-hp-latex-630',
				name: 'PrinterLatex630LandingPage',
				component: imprimantesHpLatex630
			},
			{
				path:'/imprimantes-hp-latex-700-800',
				name: 'PrinterLatex700800LandingPage',
				component: imprimantesHpLatex700800
			},
			{
				path:'/imprimantes-hp-latex-1000-2000',
				name: 'PrinterLatex10002000LandingPage',
				component: imprimantesHpLatex10002000
			},
			{
				path:'/imprimantes-hp-pagewide',
				name: 'PrinterWideLandingPage',
				component: imprimantesHpPagewide
			},
			{
				path: '/:lang(en)',
				component: Root,
				children: [
					{
						path:'/:lang(en)',
						name: 'GroupeAA en',
						component: HomeBranding
					},
					{
						path:'/:lang(en)/graphic-art-printers',
						name: 'Équipements en',
						component: PageEquipements
					},
					{
						path:'/:lang(en)/softwares',
						name: 'PageLogiciels en',
						component: PageLogiciels
					},
					{
						path:'/:lang(en)/ink-and-media',
						name: 'PageEncreMedias en',
						component: PageEncreMedias
					},
					{
						path:'/:lang(en)/services',
						name: 'Services en',
						component: PageServices
					},
					{
						path:'/:lang(en)/quote',
						name: 'Soumission en',
						component: PageSoumission
					},
					{
						path:'/:lang(en)/thank-you',
						name: 'Remerciement en',
						component: PageRemerciement
					},
					{
						path:'/:lang(en)/hp-designjet-z-series-printers',
						name: 'PrinterLandingPage en',
						component: PrinterLandingPage
					},
					{
						path:'/:lang(en)/printers-hp-latex',
						name: 'PrinterLatexLandingPage en',
						component: imprimantesHpLatex
					},
					{
						path:'/:lang(en)/printers-hp-latex-630',
						name: 'PrinterLatex630LandingPage en',
						component: imprimantesHpLatex630
					},
					{
						path:'/:lang(en)/printers-hp-latex-700-800',
						name: 'PrinterLatex700800LandingPage',
						component: imprimantesHpLatex700800
					},
					{
						path:'/:lang(en)/printers-hp-latex-1000-2000',
						name: 'PrinterLatex10002000LandingPage',
						component: imprimantesHpLatex10002000
					},
					{
						path:'/:lang(en)/printers-hp-pagewide',
						name: 'PrinterWideLandingPage en',
						component: imprimantesHpPagewide
					},
					{
						path:'/:lang(en)/*',
						name: 'NotFound fr en',
						component: NotFound
					}
				]
			},
			{
				path:'/*',
				name: 'NotFound fr',
				component: NotFound
			},
		]
	},
	{
		path:'*',
		name: 'NotFound fr en en',
		component: NotFound
	}
];