<template>
	<div>
		<div class="main-container">
			<div class="navBarTest">
				<Navbar />
			</div>
			<div class="secondary-title-wrapper col-lg-12" style="margin-bottom:-150px; border-bottom: solid #007cc3">
				<h1 class="h3 my-0 mx-lg-5 secondary-title">{{ $t('pageLogiciels.mainTitle') }}</h1>
			</div>
			<div class="row box-image-wrapper mx-auto px-lg-4 mw-100" style="display: flex;justify-content: space-around;margin-top: 175px;">
				<div class="col-lg-6 box-image position-relative text-center wow move-up" style="visibility: visible; display: flex; flex-direction: column; align-items:center;">
					<img class="imgPage w-70" src="../assets/img/hero/branding/logiciel_rip.png" :alt="$t('pageLogiciels.imgAlt')">
					<p class="text-center mt-4">{{ $t('pageLogiciels.mainTextP1') }}</p>
					<p class="text-center mt-1">{{ $t('pageLogiciels.mainTextP2') }}</p>
					<p class="text-center mt-1">{{ $t('pageLogiciels.mainTextP3') }}</p>
				</div>
				<div class="col-lg-5 col-12">
					<ContactForm :boolEmbeded="true" :ctaText="$t('pageLogiciels.callToAction')" />
				</div>
			</div>
		</div>
		<div>
			<FooterMain />
		</div>
	</div>
</template>

<script>
	import Navbar from '../components/Navbar.vue'
	import ContactForm from '../components/ContactForm.vue'
	import FooterMain from '../components/FooterMain.vue'

	export default {
		name: 'PageLogiciels',
		components: {
			Navbar,
			ContactForm,
			FooterMain,
		},
		data() {
			return {
				load: false,
				limitPosition: 200,
				scrolled: false,
				lastPosition: 500,
				langs: ['en', 'fr'],
				art: [{
						title: 'Equipements',
						link: '#'
					},
					{
						title: 'Logiciels',
						link: '#'
					},
					{
						title: 'Encre et Medias',
						link: '#'
					}
				],
				abouts: [{
						icon: require('../assets/img/hero/branding/hp.png'),
						title: 'HP serie Z'
					},
					{
						icon: require('../assets/img/hero/branding/canon.jpg'),
						title: 'Canon Pro-2100'
					},
					{
						icon: require('../assets/img/hero/branding/epson.jpg'),
						title: 'Scientifically proven progress'
					},
					{
						icon: require('../assets/img/hero/branding/epson.jpg'),
						title: 'Scientifically proven progress'
					},
					{
						icon: require('../assets/img/hero/branding/epson.jpg'),
						title: 'Scientifically proven progress'
					},
					{
						icon: require('../assets/img/hero/branding/epson.jpg'),
						title: 'Scientifically proven progress'
					}
				]
			}
		},
		metaInfo(){
			return {
				title: this.$i18n.t('tabTitle.software'),
				meta: [
					{ "name": "description", "content":  this.$i18n.t('meta.description.software') },
					{ "name": "theme-color", "content": "#007BFF;" }
				]
			}
		},
		created(){
			if(typeof window.gtag !== 'undefined'){
				window.gtag('config', process.env.VUE_APP_GTAG_UA, {
						'page_title' : this.$i18n.t('tabTitle.software'),
						'page_path': this.$i18n.t('links.logiciels')
				});
				window.gtag('config', process.env.VUE_APP_GTAG_G, {
						'page_title' : this.$i18n.t('tabTitle.software'),
						'page_path': this.$i18n.t('links.logiciels')
				});
			}
			if(typeof window.fbq !== 'undefined'){
				window.fbq('track', 'PageView');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/page-equipements.scss'
</style>