<template>
	<div class="main-container" style="display: flex;">
		<div class="navBarTest">
			<Navbar />
		</div>

		<div class="contact-form-section width-75 mobile-w-90 mx-auto pb-0 mb-0" id="contact">
			<div class="section-title text-left section-space--mb_60">
				<h1 class="font-weight--bold mb-15 wow move-up h3 my-0 main-title">{{ $t('pageSoumission.mainTitle') }}</h1>
				<p class="section-text_left wow move-up m-sm-0 m-md-0 mt-20 mw-100">{{ $t('pageSoumission.mainText') }}</p>
			</div>
			<ContactForm :boolEmbeded="false" :ctaText="$t('pageSoumission.callToAction')" />
		</div>

		<FooterMain />
	</div>
</template>

<script>
	import Navbar from '../components/Navbar.vue'
	import FooterMain from '../components/FooterMain.vue'
	import ContactForm from '../components/ContactForm.vue'

	export default {
		name: 'PageSoumission',
		components: {
			Navbar,
			FooterMain,
			ContactForm,
		},
		data() {
			return {
				load: false,
				limitPosition: 200,
				scrolled: false,
				lastPosition: 500,
				art: [{
						title: 'Equipements',
						link: '#',
					},
					{
						title: 'Logiciels',
						link: '#',
					},
					{
						title: 'Encre et Medias',
						link: '#',
					}
				]
			}
		},
		metaInfo(){
			return {
				title: this.$i18n.t('tabTitle.quote'),
				meta: [
					{ "name": "description", "content":  this.$i18n.t('meta.description.quote') },
					{ "name": "theme-color", "content": "#007BFF;" }
				]
			}
		},
		created(){
			if(typeof window.gtag !== 'undefined'){
				window.gtag('config', process.env.VUE_APP_GTAG_UA, {
						'page_title' : this.$i18n.t('tabTitle.quote'),
						'page_path': this.$i18n.t('links.soumission')
				});
				window.gtag('config', process.env.VUE_APP_GTAG_G, {
						'page_title' : this.$i18n.t('tabTitle.quote'),
						'page_path': this.$i18n.t('links.soumission')
				});
			}
			if(typeof window.fbq !== 'undefined'){
				window.fbq('track', 'PageView');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/_page-soumission.scss'
</style>