<template>
	<div class="contact-form-wrapper wow move-up" v-bind:class="{ 'p-4' : boolEmbeded }">
		<p v-if="boolEmbeded" class="cta-sentence pb-2 text-center" style="white-space: pre-line;">{{ ctaText }}</p>
		<form id="contact-form" class="form-styling" :action="form.action" method="POST" @submit.prevent="submit" @submit="$v.$touch()">
			<input type="hidden" name="oid" :value="form.oId" />
			<input type="hidden" :name="form.clientNumber" value="AA001" />
			<input type="hidden" name="retURL" :value="baseUrl + $t('links.remerciement')" />
			<input type="hidden" :name="utmIds.campaign" :value="form.utm.campaign" />
			<input type="hidden" :name="utmIds.medium" :value="form.utm.medium" />
			<input type="hidden" :name="utmIds.source" :value="form.utm.source" />
			<input type="hidden" :name="utmIds.term" :value="form.utm.term" />
			<input type="hidden" :name="utmIds.gclid" :value="form.utm.gclid" />
			<input type="hidden" :name="form.webSourceDetailField" :value="form.webSourceDetail" />

			<div class="contact-page-form">
				<div class="contact-input">
					<div class="contact-inner">
						<input v-model="$v.form.first_name.$model" id="first_name" :placeholder="$t('form.firstName')" minlength="2" maxlength="40" name="first_name" size="20" type="text" />
						<div v-if="$v.form.first_name.$error">
							<p v-if="!$v.form.first_name.required" class="text-danger my-1 mx-1">{{ $t('form.error.firstName.required') }}</p>
							<p v-if="!$v.form.first_name.minLength||!$v.form.first_name.maxLength" class="text-danger my-1 mx-1">{{ $t('form.error.firstName.length') }}</p>
						</div>
					</div>
					<div class="contact-inner">
						<input v-model="$v.form.last_name.$model" id="last_name" :placeholder="$t('form.lastName')" maxlength="80" name="last_name" size="20" type="text" />
						<div v-if="$v.form.last_name.$error">
							<p v-if="!$v.form.last_name.required" class="text-danger my-1 mx-1">{{ $t('form.error.lastName.required') }}</p>
							<p v-if="!$v.form.last_name.minLength||!$v.form.last_name.maxLength" class="text-danger my-1 mx-1">{{ $t('form.error.lastName.length') }}</p>
						</div>
					</div>
				</div>
				<div class="contact-inner">
					<input v-model="$v.form.email.$model" id="email" :placeholder="$t('form.email')" maxlength="60" name="email" size="20" type="email" />
					<div v-if="$v.form.email.$error">
						<p v-if="!$v.form.email.required" class="text-danger my-1 mx-1">{{ $t('form.error.email.required') }}</p>
						<p v-if="!$v.form.email.email" class="text-danger my-1 mx-1">{{ $t('form.error.email.email') }}</p>
						<p v-if="!$v.form.email.maxLength" class="text-danger my-1 mx-1">{{ $t('form.error.email.maxLength') }}</p>
					</div>
				</div>

				<div class="contact-inner">
					<input v-model="$v.form.phone.$model" id="phone" :placeholder="$t('form.phone')" name="phone" size="20" type="tel" />
					<div v-if="$v.form.phone.$error">
						<p v-if="!$v.form.phone.required" class="text-danger my-1 mx-1">{{ $t('form.error.phone.required') }}</p>
						<p v-if="$v.form.phone.$model && !$v.form.phone.pattern" class="text-danger my-1 mx-1">{{ $t('form.error.phone.invalid') }}</p>
					</div>
				</div>
				<div class="contact-inner">
					<input v-model="$v.form.company.$model" id="company" :placeholder="$t('form.company')" minlength="2" maxlength="100" name="company" size="20" type="text" />
					<div v-if="$v.form.company.$error">
						<p v-if="!$v.form.company.required" class="text-danger my-1 mx-1">{{ $t('form.error.company.required') }}</p>
						<p v-if="!$v.form.company.minLength||!$v.form.company.maxLength" class="text-danger my-1 mx-1">{{ $t('form.error.company.length') }}</p>
					</div>
				</div>
				<div class="contact-inner">
					<input id="city" :placeholder="$t('form.city')" name="city" size="20" type="text" />
				</div>
				<div class="contact-inner">
					<select id="serviceWanted" :name="form.serviceWantedField" :value="form.serviceWanted" style="color: #818181 !important;">
						<!--v-model="$v.form.serviceWanted.$model"-->
						<option value="">{{ $t('form.serviceWantedOptions.empty') }}</option>
						<option value="Achat et location d'équipements">{{ $t('form.serviceWantedOptions.purchaseAndRental') }}</option>
						<option value="Service d'impression">{{ $t('form.serviceWantedOptions.printingService') }}</option>
						<option value="Médias & encres">{{ $t('form.serviceWantedOptions.mediaNInk') }}</option>
					</select>
					<!-- <div v-if="$v.form.serviceWanted.$error">
						<p v-if="!$v.form.serviceWanted.required" class="text-danger my-1 mx-1">{{ $t('form.error.serviceWanted.required') }}</p>
					</div> -->
				</div>
				<div class="contact-inner contact-message">
					<textarea id="description" :placeholder="$t('form.comments')" maxlength="32000" name="description"></textarea>
				</div>
				<div class="contact-submit-btn d-flex justify-content-center">
					<button class="ht-btn ht-btn-md" v-bind:class="{ 'w-50': boolEmbeded, 'w-25': !boolEmbeded }" type="submit">{{ $t('form.submit') }}</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { helpers, required, minLength, maxLength, email } from 'vuelidate/lib/validators';

	export default {
		name: 'ContactForm',
		props: [
			'boolEmbeded',
			'ctaText'
		],
		data () {
			return {
				baseUrl: window.location.origin,
				utmIds: {
					campaign: process.env.VUE_APP_UTM_CAMPAIGN,
					medium: process.env.VUE_APP_UTM_MEDIUM,
					source: process.env.VUE_APP_UTM_SOURCE,
					term: process.env.VUE_APP_UTM_TERM,
					gclid: process.env.VUE_APP_UTM_GCLID
				},
				form: {
					action: process.env.VUE_APP_FORM_ACTION,
					oId: process.env.VUE_APP_FORM_OID,
					clientNumber: process.env.VUE_APP_CLIENT_NB_SF,
					retURL: process.env.VUE_APP_FORM_RET_URL_BASE + this.$i18n.t('links.remerciement'),
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					company: '',
					city: '',
					description: '',
					serviceWanted: '',
					serviceWantedField: process.env.VUE_APP_SERVICE_WANTED,
					webSourceDetail: window.location.href.split("?")[0],
					webSourceDetailField: process.env.VUE_APP_WEB_SOURCE_DETAIL,
					utm: {
						campaign: '',
						medium: '',
						source: '',
						term: '',
						gclid: ''
					}
				}
			}
		},
		validations: {
			form:{
				first_name: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(40)
				},
				last_name: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(80)
				},
				email: {
					required,
					maxLength: maxLength(60),
					email
				},
				phone: {
					required,
					pattern: value => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
					// pattern: helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
				},
				company: {
					required,
					minLength: minLength(2),
					maxLength: maxLength(100)
				},
				// serviceWanted: {
				// 	required
				// },
				// city: {
				// 	required,
				// 	minLength: minLength(2),
				// 	maxLength: maxLength(50),
				// },
				// description: {
				// 	required,
				// 	minLength: minLength(2),
				// 	maxLength: maxLength(32000),
				// },
			}
		},
		mounted(){
			this.setUtmCookieTracking();
		},
		methods: {
			setUtmCookieTracking(){
				const urlParams = new URLSearchParams(window.location.search);

				let campaignUtm = urlParams.get('utm_campaign') ? encodeURIComponent(urlParams.get('utm_campaign')) : null;
				let mediumUtm = urlParams.get('utm_medium') ? encodeURIComponent(urlParams.get('utm_medium')) : null;
				let sourceUtm = urlParams.get('utm_source') ? encodeURIComponent(urlParams.get('utm_source')) : null;
				let termUtm = urlParams.get('utm_term') ? encodeURIComponent(urlParams.get('utm_term')) : null;
				let gclidUtm = urlParams.get('gclid') ? encodeURIComponent(urlParams.get('gclid')) : null;

				const utmCookies = this.$cookies.get('cookieUtms') || {};

				let objectCookies = {
					campaignUtm: campaignUtm == null ? utmCookies.campaignUtm : campaignUtm,
					mediumUtm: mediumUtm == null ? utmCookies.mediumUtm : mediumUtm,
					sourceUtm: sourceUtm == null ? utmCookies.sourceUtm : sourceUtm,
					termUtm: termUtm == null ? utmCookies.termUtm : termUtm,
					gclidUtm: gclidUtm == null ? utmCookies.gclidUtm : gclidUtm
				};

				this.$cookies.set('cookieUtms', objectCookies);

				this.form.utm.campaign = objectCookies.campaignUtm;
				this.form.utm.medium = objectCookies.mediumUtm;
				this.form.utm.source = objectCookies.sourceUtm;
				this.form.utm.term = objectCookies.termUtm;
				this.form.utm.gclid = objectCookies.gclidUtm;
			},
			submit(){
				if(!this.$v.$invalid){
					document.getElementById("contact-form").submit();
				}
			}
		}
	}
</script>