<template>
	<div>
		<div class="main-container">
			<div class="navBarTest">
				<Navbar />
			</div>
			<div class="secondary-title-wrapper col-lg-12" style="margin-bottom:-150px; border-bottom: solid #007cc3">
				<h1 class="h3 my-0 mx-lg-5 secondary-title">{{ $t('hpLatex700800LandingPage.mainTitle') }}</h1>
			</div>
			<div class="row box-image-wrapper mx-auto px-lg-4 mw-100" style="display: flex;justify-content: space-around;margin-top: 175px;">
				<div class="description col-lg-6 box-image position-relative text-center wow move-up" style="visibility: visible; display: flex; flex-direction: column;align-items:center;">
					<img class="imgPage w-70" src="../assets/img/hero/branding/HP_Latex_700-800.jpg" :alt="$t('hpLatex700800LandingPage.imgAlt')">
					<p style="white-space: pre-line;" class="text-center my-4">{{ $t('hpLatex700800LandingPage.mainText') }}</p>
					<div class="row">
						<div class="col-md-6">
							<img class="imgPage w-70" src="../assets/img/hero/branding/HP_Latex_700-800_textile_printing.jpg" :alt="$t('hpLatex700800LandingPage.imgAlt')">
						</div>
						<div class="col-md-6">
							<img class="imgPage w-70" src="../assets/img/hero/branding/HP_Latex_700-800_car_wrapping.jpg" :alt="$t('hpLatex700800LandingPage.imgAlt')">
						</div>
					</div>
				</div>

				<div class="col-lg-5 col-12">
					<ContactForm :boolEmbeded="true" :ctaText="$t('hpLatex700800LandingPage.callToAction')" />
				</div>
			</div>
		</div>
		<div>
			<FooterMain />
		</div>
	</div>
</template>

<script>
	import Navbar from '../components/Navbar.vue'
    import ContactForm from '../components/ContactForm.vue'
	import FooterMain from '../components/FooterMain.vue'

	export default {
		name: 'PrinterLatex700800LandingPage',
		components: {
			Navbar,
            ContactForm,
			FooterMain
		},
		metaInfo(){
			return {
				title: this.$i18n.t('tabTitle.pageLatex700800LandingPage'),
				meta: [
					{ "name": "description", "content":  this.$i18n.t('meta.description.pageLatex700800LandingPage') },
					{ "name": "theme-color", "content": "#007BFF;" }
				]
			}
		},
		created(){
			if(typeof window.gtag !== 'undefined'){
				window.gtag('config', process.env.VUE_APP_GTAG_UA, {
						'page_title' : this.$i18n.t('tabTitle.pageLatex700800LandingPage'),
						'page_path': this.$i18n.t('links.hpLatex700800LandingPage')
				});
				window.gtag('config', process.env.VUE_APP_GTAG_G, {
						'page_title' : this.$i18n.t('tabTitle.pageLatex700800LandingPage'),
						'page_path': this.$i18n.t('links.hpLatex700800LandingPage')
				});
			}
			if(typeof window.fbq !== 'undefined'){
				window.fbq('track', 'PageView');
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/variabls.scss';
	@import '../assets/scss/elements/page-equipements.scss'
</style>