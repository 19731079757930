<template>

	<b-navbar type="light" toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area" :class="{'is-sticky': scrolled}" style="background-color:white; box-shadow: 1px 2px 3px 0px rgb(111 135 171 / 9%);}">
		<div class="container-fluid container-fluid--cp-150 flex-row justify-content-sm-center">
			<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
			<b-navbar-brand class="navbar-brand" :to="$t('links.accueil')"><img :src='"../assets/img/logo/logo_groupeaa.png"' class="main-logo" :alt="$t('navBar.logoAlt')"></b-navbar-brand>

			<b-collapse class="default-nav justify-content-end" is-nav id="nav_collapse">
				<b-navbar-nav class="navbar-nav main-menu">
					<b-nav-item :to="$t('links.accueil')" class="scroll" >
						{{ $t('navBar.home') }}
					</b-nav-item>
					<b-nav-item :to="$t('links.equipements')" class="scroll" @mouseover="mouseover()" @mouseleave="mouseleave()">
						<span style="position: relative;">
							<span>{{ $t('navBar.equipments') }}</span>
							<div class="dropdown-menu sub-menu" :class="isDropdownOpen? 'open' : ''" style="width: 100%; position: absolute; top: 85%; left: 0;min-width: 223px;">
								<b-nav-item :to="$t('links.printerLandingPage')" class="scroll sub-nav-item" >{{ $t('navBar.printerLandingPage') }}</b-nav-item>
								<b-nav-item :to="$t('links.hpLatexLandingPage')" class="scroll sub-nav-item" >{{ $t('navBar.hpLatexLandingPage') }}</b-nav-item>
								<b-nav-item :to="$t('links.hpLatex630LandingPage')" class="scroll sub-nav-item" >{{ $t('navBar.hpLatex630LandingPage') }}<span class="float-right"><small>{{ $t('general.new') }}</small></span></b-nav-item>
								<b-nav-item :to="$t('links.hpLatex700800LandingPage')" class="scroll sub-nav-item" >{{ $t('navBar.hpLatex700800LandingPage') }}<span class="float-right"><small>{{ $t('general.new') }}</small></span></b-nav-item>
								<b-nav-item :to="$t('links.hpLatex10002000LandingPage')" class="scroll sub-nav-item" >{{ $t('navBar.hpLatex10002000LandingPage') }}<span class="float-right"><small>{{ $t('general.new') }}</small></span></b-nav-item>
								<b-nav-item :to="$t('links.pageWideLandingPage')" class="scroll sub-nav-item" >{{ $t('navBar.pageWideLandingPage') }}</b-nav-item>
							</div>
						</span>
					</b-nav-item>
					<b-nav-item :to="$t('links.logiciels')" class="scroll">
						{{ $t('navBar.softwares') }}
					</b-nav-item>
					<b-nav-item :to="$t('links.mediasEtEncres')" class="scroll">
						{{ $t('navBar.inkMedia') }}
					</b-nav-item>
					<b-nav-item :to="$t('links.services')" class="scroll">
						{{ $t('navBar.services') }}
					</b-nav-item>
				</b-navbar-nav>

				<div class="slider-button mt-0 d-xl-flex align-items-center">
					<router-link :to="$t('links.soumission')" class="ht-btn ht-btn-md mx-3">{{ $t('navBar.quoteBtn') }}</router-link>
					<b-form-select v-model="$i18n.locale" :options="options" @change="onLanguageChange($event)" class="langSwitcher mx-auto"></b-form-select>
				</div>
			</b-collapse>
		</div>
	</b-navbar>


</template>


<script>

	export default {
		name: 'navbar',
		components: {},
		data(){
			return {
				options:[
					{ value: 'fr', text: 'Fr' },
					{ value: 'en', text: 'En' }
				],
				scrolled: false,
				isDropdownOpen: false
			}
		},
		methods: {
			onLanguageChange(){

				let newRoute = {name: '', params: {}};
				let actualRoute = this.$route.name;

				if(this.$i18n.locale == 'fr'){
					newRoute.name = actualRoute.slice(0, -3);
				}else{
					newRoute.name = actualRoute + ' en';
					newRoute.params.lang = 'en';
				}

				this.$router.push(newRoute);

			},
			mouseover () {
				this.isDropdownOpen = true;
			},
			mouseleave () {
				this.isDropdownOpen = false;
			}
		}
	}

</script>

<style scoped>

	.navbar-light .main-menu li .nav-link{
		padding-left: 16px;
		padding-right: 16px;
	}

	.dropdown-menu.sub-menu{
		display: none;
	}

	.dropdown-menu.sub-menu .sub-nav-item .nav-link{
		padding: 8px;
	}

	.dropdown-menu.sub-menu.open{
		display: block;
	}

</style>