<template>
    <div class="main-container">

        <!-- Navbar section -->
        <Navbar />

        <!-- hero section -->
        <HeroBranding />

        <!-- art grahique section -->
        <div class="box-image-area section-space--ptb_60 m-t-50vh" id="about">
            <div class="container mobile-mw-100">
                <div class="section-title-wrapper text-center section-space--mb_15 wow move-up">
                    <router-link :to="$t('links.equipements')" id="titreLien" class="main-title mb-15">{{ $t('homePage.graphicArtTitle') }}</router-link>
                    <p class="section-text py-3">{{ $t('homePage.graphicArtText') }}</p>
                </div>
                <div class="row box-image-wrapper d-flex flex-row justify-content-center">
                    <div class="box-image__media d-flex flex-row justify-content-center wow move-up width-50 mobile-w-100">
                        <img class="w-65 mobile-w-90 sm-to-lg-mobile-w-50 tablet-w-100" src="../assets/img/hero/branding/hp_designjet_44_2rolls.png" :alt="$t('homePage.graphicArtImgAlt')">
                    </div>
                </div>
            </div>
        </div>
        <!-- featured elements section -->
        <FeatureList />
        <RequireList />
        <ServicesList />

        <!-- footer section -->
        <FooterMain />
    </div>
</template>

<script>
    import Navbar from '../components/Navbar.vue'
    import HeroBranding from '../components/HeroBranding.vue'
    import FeatureList from '../components/FeatureList.vue'
    import RequireList from '../components/RequireList.vue'
    import ServicesList from '../components/ServicesList.vue'
    import FooterMain from '../components/FooterMain.vue'

    export default {
        name: 'app',
        components: {
            Navbar,
            HeroBranding,
            FeatureList,
            RequireList,
            ServicesList,
            FooterMain,
        },
		data(){
			return{
				testVar1 : process.env.VUE_APP_GTAG_UA
			}
		},
        metaInfo(){
            return {
                title: this.$i18n.t('tabTitle.home'),
                meta: [
                    { "name": "description", "content":  this.$i18n.t('meta.description.home') },
                    { "name": "theme-color", "content": "#007BFF;" }
                ]
            }
        },
        created(){
            if(typeof window.gtag !== 'undefined'){
                window.gtag('config', process.env.VUE_APP_GTAG_UA, {
                'page_title' : this.$i18n.t('tabTitle.home'),
                'page_path': this.$i18n.t('links.accueil')
                });
                window.gtag('config', process.env.VUE_APP_GTAG_G, {
                    'page_title' : this.$i18n.t('tabTitle.home'),
                    'page_path': this.$i18n.t('links.accueil')
                });
            }
            if(typeof window.fbq !== 'undefined'){
                window.fbq('track', 'PageView');
            }
        }
    }
</script>
