<template>
	<div class="main-container">
		<div class="navBarTest">
			<Navbar />
		</div>
		<div class="thanks my-5 py-5 d-flex flex-column d-flex justify-content-center d-flex align-items-center">
            <div class="error-image section-space--mb_50 d-flex justify-content-center mt-5 pt-5">
                <img class="img-fluid w-50" src="../../assets/img/other/page-404-image.png" alt="Image Not Found">
            </div>
			<h1 class="mb-3 h3">{{ $t('pageErreur404.mainTitle') }}</h1>
			<router-link :to="$t('links.accueil')" class="mt-3 tertiary-title">&lsaquo; {{ $t('general.backToHomePage') }}</router-link>
		</div>
		<div>
			<FooterMain />
		</div>
	</div>
</template>

<script>
	import Navbar from '../../components/Navbar.vue'
	import FooterMain from '../../components/FooterMain.vue'

    export default {
        name: 'NotFound',
        components: {
				Navbar,
				FooterMain
			},
			metaInfo(){
				return {
					title: this.$i18n.t('tabTitle.notFound'),
					meta: [
						{ "name": "theme-color", "content": "#007BFF;" },
						{ "name": "robots", "content": "noindex, nofollow"}
					]
				}
			},
			created(){
				if(typeof window.gtag !== 'undefined'){
					window.gtag('config', process.env.VUE_APP_GTAG_UA, {
						'page_title' : this.$i18n.t('tabTitle.error404'),
						'page_path': '/error404'
					});
					window.gtag('config', process.env.VUE_APP_GTAG_G, {
						'page_title' : this.$i18n.t('tabTitle.error404'),
						'page_path': '/error404'
					});
				}
				if(typeof window.fbq !== 'undefined'){
					window.fbq('track', 'PageView');
				}
			}
    }
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/variabls.scss';
</style>